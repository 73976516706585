<template>
  <Button
    v-if="isFiltersApplied"
    class="button--small button--secondary u-text-transform--uppercase"
    :on-click="emitClick"
    data-cy="search-clear-filters-button"
  >
    clear all filters
  </Button>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Button from "atlas/src/components/Button/Button.vue";
import { SearchClearFiltersButtonProps } from "./Props";

export default Vue.extend({
  name: "SearchClearFiltersButton",
  components: {
    Button,
  },
  props: {
    filters: {
      type: Object as PropType<SearchClearFiltersButtonProps["filters"]>,
      required: true,
    },
  },
  computed: {
    isFiltersApplied() {
      if (
        this.filters.style.length > 0 ||
        this.filters.theme.length > 0 ||
        this.filters.minDuration != null ||
        this.filters.maxDuration != null ||
        this.filters.departingDate != null ||
        this.filters.finishingDate != null ||
        this.filters.onSale === true
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
});
</script>
