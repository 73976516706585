<template>
  <div class="search-trip-count" data-cy="search-trip-count">
    <template v-if="loading">
      <span class="search-trip-count__spinner">
        <atlas-spinner :show="loading" />
      </span>
      finding trips...
    </template>

    <template v-else-if="!resultCount">No trips found</template>

    <span v-else role="status">
      <strong>{{ resultCount }}</strong> {{ tripsFoundText }}
    </span>
  </div>
</template>

<script lang="ts">
import Spinner from "atlas/src/components/Spinner/Spinner.vue";
import Vue, { PropType } from "vue";
import { SearchTripsCountProps } from "./Props";

export default Vue.extend({
  name: "SearchTripCount",
  components: {
    "atlas-spinner": Spinner,
  },
  props: {
    resultCount: {
      type: Number as PropType<SearchTripsCountProps["resultCount"]>,
      default: null,
    },
    loading: {
      type: Boolean as PropType<SearchTripsCountProps["loading"]>,
      required: true,
    },
  },
  computed: {
    tripsFoundText() {
      return this.resultCount === 1 ? "trip found" : "trips found";
    },
  },
});
</script>
