<template>
  <div class="search-show-trips-button">
    <Button
      class="button--primary button--block"
      :on-click="emitClick"
      data-cy="search-show-trips-button"
      :aria-label="ariaLabel"
    >
      <Spinner :show="loading" />
      <template v-if="!loading">
        {{ buttonText }}
      </template>
    </Button>
  </div>
</template>

<script lang="ts">
import Button from "atlas/src/components/Button/Button.vue";
import Spinner from "atlas/src/components/Spinner/Spinner.vue";
import Vue, { PropType } from "vue";
import { SearchShowTripsButtonProps } from "./Props";

export default Vue.extend({
  name: "SearchShowTripsButton",
  components: {
    Button,
    Spinner,
  },
  props: {
    resultCount: {
      type: Number as PropType<SearchShowTripsButtonProps["resultCount"]>,
      default: 0,
    },
    loading: {
      type: Boolean as PropType<SearchShowTripsButtonProps["loading"]>,
      required: true,
    },
  },
  computed: {
    buttonText(): string {
      if (!this.resultCount) {
        return "No results found";
      }

      if (this.resultCount && this.resultCount === 1) {
        return `Show ${this.resultCount} trip`;
      }

      return `Show ${this.resultCount} trips`;
    },
    ariaLabel(): string {
      if (this.loading) {
        return "Loading";
      }

      return this.buttonText;
    },
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
});
</script>

<style lang="scss">
@import "../../components/SearchShowTripsButton/search-show-trips-button";
</style>
