<template>
  <Tile
    :title="title"
    :icon="icon"
    class="tile--x-large tile--text-left"
    data-cy="search-no-results-tile"
  >
    <p>
      We’ve got 1000+ trips in 100+ countries, so
      <span class="u-font-weight--bold">let’s find your next adventure.</span>
    </p>
    <p>Review your search or find what you’re looking for somewhere else.</p>
    <div class="u-font-weight--bold">Review your search</div>
    <ul class="u-margin-bottom--2 u-margin-top--0">
      <li>Check for spelling errors.</li>
      <li>Try a different search term.</li>
      <li>Adjust filters to refine your search.</li>
      <li>
        <Link :href="$link.create(`/search?page=1&sort=relevance`)"
          >Clear your search</Link
        >
        to explore all trips.
      </li>
    </ul>

    <div class="u-font-weight--bold">Check somewhere else</div>
    <ul class="u-margin-bottom--2 u-margin-top--0">
      <li>
        <Link :href="$link.create(`/destinations`)">Explore our top trips</Link
        >.
      </li>
      <li>
        Travelling in a group?
        <Link :href="$link.create(`/tailor-made`)"
          >Build your dream adventure with Tailor-Made</Link
        >.
      </li>
      <li>
        <Link :href="$link.create(`/contact-us/frequently-asked-questions`)"
          >Browse our FAQs</Link
        >.
      </li>
      <li>
        <Link :href="$link.create(`/contact-us`)" target="_blank"
          >Contact us</Link
        >. We’re here for you 24 hours 7 days a week.
      </li>
    </ul>
  </Tile>
</template>

<script lang="ts">
import Vue from "vue";
import Tile from "atlas/src/components/Tile/Tile.vue";
import Link from "../Link/Link.vue";

export default Vue.extend({
  name: "SearchNoResultsTile",
  components: {
    Tile,
    Link,
  },
  props: {
    keyword: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      icon: "no-trip-map",
    };
  },
  computed: {
    title() {
      return `No trips match your search for “${this.$props.keyword}”`;
    },
  },
});
</script>
