import SearchProductModel from "search-client-js/src/Model/Product";
import SearchDepartureModel from "search-client-js/src/Model/Departure";
import SearchImageModel from "search-client-js/src/Model/Image";
import SearchPriceModel from "search-client-js/src/Model/Price";
import { isNotNull } from "~/lib/utils/isNotNull";
import { Trip } from "~/lib/types/Mocks/Trip";
import { Price } from "~/lib/types/Mocks/Price";
import Image from "~/lib/types/Image";
import { Departure } from "~/lib/types/Mocks/Departure";
import { Destination } from "~/lib/types/Mocks/Destination";
import { Style } from "~/lib/types/Mocks/Style";
import { getLowQualitySrc, getSrcSet } from "~~/lib/utils/image/imageService";
import { CurrencyCode } from "~/lib/types/Currency";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";

const logger = loggerFactory({
  tags: [logTags.Layer.Decorator, logTags.Feature.Search],
});

export const AlgoliaSearchResultsDecorator =
  () =>
  (results: SearchProductModel[], currencyCode: CurrencyCode): Trip[] => {
    const convertedProducts = Array.isArray(results)
      ? results.map((result) => {
          return convertProduct(result, currencyCode);
        })
      : [];
    return convertedProducts.filter(isNotNull);
  };

const convertProduct = (
  product: SearchProductModel,
  currencyCode: CurrencyCode
): Trip | null => {
  try {
    return {
      code: typeof product.code === "string" ? product.code : undefined,
      name: typeof product.name === "string" ? product.name : undefined,
      duration:
        typeof product.duration === "number" ? product.duration : undefined,
      destinations: convertDestinations(product.destinations),
      themes: convertThemes(product.themes),
      styles: convertStyles(product.styles),
      map: convertProductMap(product.map),
      url: product.url ? product.url : "",
      departure: product.departure
        ? convertDeparture(product.departure, currencyCode)
        : {},
    };
  } catch (e) {
    logger.error("Error converting product", {
      SearchProduct: product,
      error: e,
    });
    return null;
  }
};

export const convertDestinations = (
  destinations: string[] | undefined
): Destination[] => {
  return Array.isArray(destinations)
    ? destinations.map((destination: string) => {
        return {
          name: destination,
        };
      })
    : [];
};

export const convertThemes = (
  themes: string[] | undefined
): { title: string }[] => {
  return Array.isArray(themes)
    ? themes.map((theme: string) => {
        return {
          title: theme,
        };
      })
    : [];
};

export const convertStyles = (styles: string[] | undefined): Style[] => {
  return Array.isArray(styles)
    ? styles.map((style: string) => {
        return {
          name: style,
        };
      })
    : [];
};

export const convertDeparture = (
  departure: SearchDepartureModel | undefined,
  currencyCode: CurrencyCode
): Departure | undefined => {
  if (!departure) return;
  return {
    startDate:
      typeof departure?.startDate === "number"
        ? departure.startDate
        : undefined,
    placesLeft:
      typeof departure?.placesLeft === "number"
        ? departure.placesLeft
        : undefined,
    lowestPrice: convertPrice(departure?.price[currencyCode]),
  };
};

export const convertProductMap = (
  map: SearchImageModel | undefined
): Image | undefined => {
  if (!map || !map.url) return;
  return {
    src: getLowQualitySrc(map.url),
    srcSet: getSrcSet(map.url),
    alt: map.alt,
    title: map.title,
    width: map.width,
    height: map.height,
  };
};

export const convertPrice = (
  price: SearchPriceModel | undefined
): Price | undefined => {
  if (!price) return;
  return {
    totalPrice: price.price,
    discountedTotalPrice: price.discountPrice,
    type: price.type,
    onSale: price.onSale,
    currencyCode: price.currencyCode as CurrencyCode,
  };
};
