<template>
  <div class="min-max-filter" data-cy="min-max-filter">
    <div class="min-max-filter__wrapper">
      <div class="min-max-filter__min">
        <SelectField
          :id="componentUniqueId + '-min'"
          v-model.number="minModel"
          type="number"
          name="min"
          label="Min"
          data-cy="min-max-filter__min"
          :options="options"
        ></SelectField>
      </div>
      <div class="min-max-filter__to">to</div>
      <div class="min-max-filter__max">
        <SelectField
          :id="componentUniqueId + '-max'"
          v-model.number="maxModel"
          type="number"
          name="max"
          label="Max"
          data-cy="min-max-filter__max"
          :options="options"
        ></SelectField>
      </div>
    </div>
    <div class="u-text-align--right">
      <Button
        class="button--small button--secondary u-margin-top--1"
        :on-click="clearFilter"
        data-cy="min-max-filter__clear"
      >
        Clear duration
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import SelectField from "atlas/src/components/SelectField/SelectField.vue";
import Button from "atlas/src/components/Button/Button.vue";
import { MinMaxFilterProps } from "./Props";
import { componentUniqueIdMixin } from "~~/lib/mixins/componentUniqueId";

const ANY = "any";

type MinMaxSelectOptions = {
  id: string | number;
  value: string;
  disabled: boolean;
};

export default Vue.extend({
  name: "MinMaxFilter",
  components: {
    SelectField,
    Button,
  },
  mixins: [componentUniqueIdMixin],
  props: {
    minValue: {
      type: Number as PropType<MinMaxFilterProps["minValue"]>,
      required: false,
      validator: (value) => typeof value === "number" || value === null,
      default: null,
    },
    maxValue: {
      type: Number as PropType<MinMaxFilterProps["maxValue"]>,
      required: false,
      validator: (value) => typeof value === "number" || value === null,
      default: null,
    },
    minFacet: {
      type: Number as PropType<MinMaxFilterProps["minFacet"]>,
      required: false,
      validator: (value) => typeof value === "number" || value === null,
      default: null,
    },
    maxFacet: {
      type: Number as PropType<MinMaxFilterProps["maxFacet"]>,
      required: false,
      validator: (value) => typeof value === "number" || value === null,
      default: null,
    },
    facets: {
      type: Object as PropType<MinMaxFilterProps["facets"]>,
      required: true,
    },
  },
  computed: {
    minModel: {
      get(): number | string {
        return this.convertValueToModel(this.minValue);
      },
      set(value: number | string): void {
        this.$emit("min", this.convertModelToValue(value));
      },
    },
    maxModel: {
      get(): number | string {
        return this.convertValueToModel(this.maxValue);
      },
      set(value: number): void {
        this.$emit("max", this.convertModelToValue(value));
      },
    },
    options(): MinMaxSelectOptions[] {
      let lowestNumber = this.convertModelToValue(this.minValue);
      let highestNumber = this.convertModelToValue(this.maxValue);

      if (!lowestNumber && this.minFacet) {
        lowestNumber = this.minFacet;
      }
      if (!highestNumber && this.maxFacet) {
        highestNumber = this.maxFacet;
      }
      if (!lowestNumber && highestNumber) {
        lowestNumber = highestNumber;
      }
      if (!highestNumber && lowestNumber) {
        highestNumber = lowestNumber;
      }

      return this.generateOptionsBetween(lowestNumber, highestNumber);
    },
  },

  methods: {
    clearFilter() {
      this.$emit("clear", true);
    },
    generateOptionsBetween(
      lowestNumber: number | null,
      highestNumber: number | null
    ) {
      const options: MinMaxSelectOptions[] = [
        {
          id: ANY,
          value: "Any",
          disabled: false,
        },
      ];

      if (!highestNumber && !lowestNumber) {
        return options;
      }

      for (let i = Number(lowestNumber); i <= Number(highestNumber); i++) {
        let disabled = true;
        if (Object.prototype.hasOwnProperty.call(this.facets, i)) {
          disabled = false;
        }
        options.push({
          id: i,
          value: `${i} days`,
          disabled,
        });
      }

      return options;
    },
    convertModelToValue(model: number | string): number | null {
      if (model === ANY || typeof model === "string") {
        return null;
      }
      return model;
    },
    convertValueToModel(value: null | number): number | string {
      if (value === null) {
        return ANY;
      }
      return value;
    },
  },
});
</script>

<style lang="scss">
@import "./min-max-filter";
</style>
