import Image from "~/lib/types/Image";

const srcSetSizes = [
  "75w",
  "160w",
  "320w",
  "480w",
  "640w",
  "800w",
  "960w",
  "1080w",
  "1240w",
  "1400w",
  "1920w",
  "2560w",
];
const fileDirectoryPath = "sites/intrepid/files";

export const getSrcSet = (imagePath: string): Image["srcSet"] | [] => {
  const uri = imagePath?.split(fileDirectoryPath);

  if (!uri[0] || !uri[1]) {
    return [];
  }

  const srcSet: Image["srcSet"] = [];
  srcSetSizes.forEach((size) => {
    if (size) {
      srcSet?.push({
        src:
          uri[0] + fileDirectoryPath + "/styles/" + size + "/public" + uri[1],
        size,
      });
    }
  });
  return srcSet;
};

export const getLowQualitySrc = (imagePath: string) => {
  const uri = imagePath?.split(fileDirectoryPath);

  if (!uri[0] || !uri[1]) {
    return imagePath;
  }
  return uri[0] + fileDirectoryPath + "/styles/low-quality/public" + uri[1];
};
