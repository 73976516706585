<template>
  <div class="search-messages">
    <template v-if="searchError">
      <Imagery
        src="/files/images/illustrations/llama.svg"
        alt="Llama looking lost in the desert"
        :height="177"
        :width="240"
        type="static"
        background-color="#ffffff"
      />

      <h3>
        As with all great adventures, sometimes things don't go according to
        plan.
      </h3>

      <p class="u-margin-bottom--2">
        Trip search is temporarily unavailable. While we work to fix it, why not
        check out our top travel destinations?
      </p>

      <Button class="button--secondary" :href="$link.create('/destinations')">
        View top destinations
      </Button>
    </template>

    <template v-else-if="!hasResults">
      <SearchNoResultsTile :keyword="keyword" />
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Imagery from "atlas/src/components/Imagery/Imagery.vue";
import Button from "atlas/src/components/Button/Button.vue";
import { SearchMessagesProps } from "./Props";
import SearchNoResultsTile from "~/components/SearchNoResultsTile/SearchNoResultsTile.vue";

export default Vue.extend({
  name: "SearchMessages",
  components: {
    Button,
    Imagery,
    SearchNoResultsTile,
  },
  props: {
    searchError: {
      type: Boolean as PropType<SearchMessagesProps["searchError"]>,
      required: true,
    },
    keyword: {
      type: String,
      required: true,
    },
    hasResults: {
      type: Boolean as PropType<SearchMessagesProps["hasResults"]>,
      required: true,
    },
  },
});
</script>
