<template>
  <Page v-bind="page">
    <ErrorBoundary
      v-if="pageHeadline"
      class="u-display--none md:u-display--block"
    >
      <PageHeadline v-if="pageHeadline" :title="pageHeadline.title" />
    </ErrorBoundary>

    <client-only>
      <div class="l-container u-margin-top--2 md:u-margin-top--4">
        <div v-cloak ref="searchResults" class="l-grid">
          <ErrorBoundary
            class="
              l-grid__cell
              l-grid__cell--12-col-desktop
              l-grid__cell--6-col-tablet
              l-grid__cell--4-col-phone
            "
          >
            <form action="#" @submit.prevent>
              <SearchAutocomplete
                v-if="isKeywordSearchControlAvailable() && autocomplete"
                :autocomplete="autocomplete"
                :initial-search="initialAutocompleteValue()"
                @search="autocompleteSearch"
                @updateLoadingState="loadingState"
              ></SearchAutocomplete>
            </form>
          </ErrorBoundary>
          <ErrorBoundary
            class="
              l-grid__cell
              l-grid__cell--2-col-tablet
              l-grid__cell--4-col-phone
              l-grid__cell--hide-desktop
              u-margin-bottom--1
            "
          >
            <SearchFilterButton
              :filters="filters"
              @click="toggleDrawer"
            ></SearchFilterButton>

            <Drawer
              class="drawer--left"
              :show-drawer="showDrawer"
              :offset-top="drawerOffsetTop"
              @close="toggleDrawer"
            >
              <div
                class="u-margin-right--1 u-margin-left--1 u-margin-bottom--4"
              >
                <span class="headline--4 u-margin-bottom--1 u-display--block"
                  >Filters</span
                >
                <SearchClearFiltersButton
                  class="u-margin-bottom--1"
                  :filters="filters"
                  @click="clearFiltersAndSearch"
                ></SearchClearFiltersButton>
                <SelectField
                  id="sort-by-select-drawer"
                  v-model="sortOptionModel"
                  type="text"
                  name="sortBySelect"
                  :options="sortOptions"
                  label="Sort by"
                  class="u-margin-bottom--1"
                  data-cy="sort-by-select__drawer"
                  @input="sortUpdate"
                ></SelectField>
                <SearchFilters
                  :filters="filters"
                  :facets="facets"
                  :facets-stats="facetsStats"
                  @update="search(1)"
                />
              </div>

              <SearchShowTripsButton
                :result-count="resultCount"
                :loading="loading"
                @click="toggleDrawer"
              ></SearchShowTripsButton>
            </Drawer>
          </ErrorBoundary>

          <ErrorBoundary
            class="
              l-grid__cell
              l-grid__cell--12-col
              l-grid__cell--hide-phone
              l-grid__cell--hide-tablet
              u-margin-top--0
              u-margin-bottom--0
            "
          >
            <SearchAppliedFilters
              :filters="filters"
              @clearfilters="clearFiltersAndSearch"
              @update="search(1)"
            ></SearchAppliedFilters>
          </ErrorBoundary>

          <div class="l-grid__cell l-grid__cell--12-col u-margin-top--0">
            <SearchTripCount :result-count="resultCount" :loading="loading" />
          </div>

          <ErrorBoundary
            class="
              l-grid__cell
              l-grid__cell--3-col
              l-grid__cell--hide-phone
              l-grid__cell--hide-tablet
            "
          >
            <SelectField
              id="sort-by-select"
              v-model="sortOptionModel"
              type="text"
              name="sortBySelect"
              class="u-margin-bottom--2"
              :options="sortOptions"
              label="Sort by"
              data-cy="sort-by-select"
              @input="sortUpdate"
            />

            <SearchFilters
              :filters="filters"
              :facets="facets"
              :facets-stats="facetsStats"
              @update="search(1)"
            />
          </ErrorBoundary>

          <div
            class="
              l-grid__cell
              l-grid__cell--9-col
              l-grid__cell--12-col-tablet
              l-grid__cell--12-col-phone
            "
          >
            <SearchResults
              v-if="hasResults && !hasSearchError"
              :trips="decoratedSearchResults"
            />

            <SearchMessages
              :has-results="hasResults"
              :search-error="hasSearchError"
              :keyword="keyword"
            />

            <div
              class="
                u-display--flex
                u-justify-content--flex-end
                u-margin-top--2
                u-margin-bottom--1-5
              "
            >
              <Pagination
                v-if="hasResults && !hasSearchError"
                :page="currentPage"
                :totalPages="pages"
                class="u-margin-bottom--2"
                @previous="paginationPrevious"
                @next="paginationNext"
              ></Pagination>
            </div>
          </div>
        </div>
      </div>
      <ErrorBoundary class="l-container xs:u-margin-top--4 md:u-margin-top--6">
        <div class="l-grid u-display--flex u-justify-content--flex-end">
          <div
            class="
              l-grid__cell
              l-grid__cell--9-col-desktop
              l-grid__cell--6-col-tablet
              l-grid__cell--12-col-phone
              u-margin-top--0
            "
          >
            <TailorMadeCard v-bind="tailorMadeCard" />
          </div>
        </div>
      </ErrorBoundary>
    </client-only>
  </Page>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { SortOption } from "search-client-js/types";
import SelectField from "atlas/src/components/SelectField/SelectField.vue";
import Drawer from "atlas/src/components/Drawer/Drawer.vue";
import ProductAutocomplete from "search-client-js/src/Service/ProductAutocomplete";
import ProductSearchService from "search-client-js/src/Service/ProductSearch";
import SearchClientError from "search-client-js/src/Error/SearchClientError";
import Filters from "search-client-js/src/Model/Filters";
import Pagination from "atlas/src/components/Pagination/Pagination.vue";
import WindowSize from "atlas/src/mixins/WindowSize/WindowSize.js";
import {
  SearchFilterFacets,
  SearchFilterFacetsStats,
} from "../SearchFilters/Props";
import { SearchPageProps, SearchPageDataType } from "./Props";
import { Trip } from "~/lib/types/Mocks/Trip";
import { AlgoliaTripCardDecorator } from "~/components/TripCard/AlgoliaTripCardDecorator";
import { AlgoliaSearchResultsDecorator } from "~/components/SearchResults/AlgoliaSearchResultsDecorator";
import SearchResults from "~/components/SearchResults/SearchResults.vue";
import SearchFilters from "~/components/SearchFilters/SearchFilters.vue";
import SearchAppliedFilters from "~/components/SearchAppliedFilters/SearchAppliedFilters.vue";
import SearchTripCount from "~/components/SearchTripCount/SearchTripCount.vue";
import SearchShowTripsButton from "~/components/SearchShowTripsButton/SearchShowTripsButton.vue";
import SearchClearFiltersButton from "~/components/SearchClearFiltersButton/SearchClearFiltersButton.vue";
import SearchFilterButton from "~/components/SearchFilterButton/SearchFilterButton.vue";
import SearchMessages from "~/components/SearchMessages/SearchMessages.vue";
import SearchAutocomplete from "~/components/SearchAutocomplete/SearchAutocomplete.vue";
import { CurrencyCode } from "~~/lib/types/Currency";
import Page from "~/components/Page/Page.vue";
import PageHeadline from "~/components/PageHeadline/PageHeadline.vue";
import ErrorBoundary from "~~/components/ErrorBoundary/ErrorBoundary.vue";
import { getLocaleByCode } from "~/lib/utils/locale/getLocaleByCode";
import TailorMadeCard from "~/components/TailorMadeCard/TailorMadeCard.vue";

type Data = {
  index: ProductSearchService | undefined;
  resultCount: number | undefined;
  resultsPerPage: number | null;
  currentPage: number;
  pages: number;
  facets: SearchFilterFacets | object | undefined;
  facetsStats: SearchFilterFacetsStats | object | undefined;
  trips: Trip[];
  showDrawer: boolean;
  sort: SortOption;
  hasResults: boolean;
  sortOptions: {
    id: string;
    value: string;
  }[];
  sortOptionModel: SortOption | undefined;
  hasSearchError: boolean;
  loading: boolean;
  autocomplete: ProductAutocomplete | undefined;
  drawerOffsetTop: number;
};

export default Vue.extend({
  name: "SearchPage",
  components: {
    Page,
    SearchResults,
    SearchTripCount,
    SearchAppliedFilters,
    Pagination,
    SelectField,
    Drawer,
    SearchShowTripsButton,
    SearchClearFiltersButton,
    SearchFilterButton,
    SearchFilters,
    SearchMessages,
    SearchAutocomplete,
    PageHeadline,
    ErrorBoundary,
    TailorMadeCard,
  },
  mixins: [WindowSize],
  props: {
    page: {
      type: Object as PropType<SearchPageProps["page"]>,
      required: true,
    },
    pageHeadline: {
      type: Object as PropType<SearchPageProps["pageHeadline"]>,
      required: true,
    },
    tailorMadeCard: {
      type: Object as PropType<SearchPageProps["tailorMadeCard"]>,
      required: true,
    },
  },
  data(): Data {
    return {
      index: undefined,
      resultCount: undefined,
      resultsPerPage: null,
      currentPage: 0,
      pages: 0,
      facets: {},
      facetsStats: {},
      trips: [],
      showDrawer: false,
      sort: "relevance",
      hasResults: true,
      sortOptions: [
        {
          id: "relevance",
          value: "Relevance",
        },
        {
          id: "price",
          value: "Price (lowest to highest)",
        },
        {
          id: "duration",
          value: "Duration (shortest to longest)",
        },
      ],
      sortOptionModel: "relevance",
      hasSearchError: false,
      loading: false,
      autocomplete: undefined,
      drawerOffsetTop: 0,
    };
  },
  computed: {
    filters() {
      return this.index?.params.filters as Filters;
    },
    algoliaPublicSearchKey(): string {
      return this.$store.getters["search/algoliaPublicSearchKey"];
    },
    decoratedSearchResults() {
      return this.trips.map((trip) => AlgoliaTripCardDecorator()(trip));
    },
    keyword() {
      return this.index?.params.keyword ?? "";
    },
  },
  created() {
    if (process.client) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const { Search } = require("search-client-js/search");
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const { Autocomplete } = require("search-client-js/autocomplete");

      this.onWindowResize(this.setDrawerOffsetTop);

      this.index = Search.initProductSearch(
        this.algoliaPublicSearchKey,
        this.$config.public.algoliaEnvironment,
        "intrepid",
        ["themes", "duration", "styles"],
        (this.$route.params.currencyCode ||
          this.$i18n.localeProperties.currencyCode) as CurrencyCode
      ) as ProductSearchService;
      this.index.params.filters!.excludedSaleRegion = this.$i18n.locale;
      this.sortOptionModel = this.index.params.sort ?? undefined;
      this.currentPage = this.index.params.page ?? 0;
      this.search(this.index.params.page ?? 0);

      this.autocomplete = Autocomplete.initProductAutocomplete(
        this.algoliaPublicSearchKey,
        this.$config.public.algoliaEnvironment,
        "intrepid",
        "default"
      );
    }
  },
  methods: {
    setFocus() {
      if (this.$refs.searchResults) {
        (this.$refs.searchResults as Element).scrollIntoView();
      }
    },
    paginationPrevious(): void {
      --this.currentPage;
      this.search(this.currentPage);
      this.setFocus();
    },
    paginationNext(): void {
      ++this.currentPage;
      this.search(this.currentPage);
      this.setFocus();
    },
    getLocaleByCode,
    search(page: number) {
      if (!this.index) {
        return;
      }

      this.loadingState(true);

      this.index.params.page = page;

      this.index
        .search()
        .then((search) => {
          this.trips = AlgoliaSearchResultsDecorator()(
            search.results,
            (this.$route.params.currencyCode ||
              this.$i18n.localeProperties.currencyCode) as CurrencyCode
          );

          this.resultCount = search.resultCount;
          this.resultsPerPage = search.resultsPerPage;
          this.currentPage = search.currentPage;
          this.pages = search.pages;
          this.hasResults = this.checkHasResults(search.resultCount);
          this.facets = search.facets;
          this.facetsStats = search.facetsStats;
          this.hasSearchError = false;

          window.setTimeout(() => {
            this.loadingState(false);
          }, 750);

          this.mapSearchPageToUtagSearch();
        })
        .catch((error) => {
          if (error instanceof SearchClientError) {
            this.resultCount = 0;
            this.hasSearchError = true;
          }

          this.$handleError(error);
          this.loadingState(false);
        });
    },
    loadingState(state: boolean) {
      this.loading = state;
    },
    checkHasResults(resultCount: number) {
      return resultCount > 0;
    },
    clearFilters() {
      this.index!.params!.filters!.style = [];
      this.index!.params!.filters!.theme = [];
      this.index!.params!.filters!.minDuration = null;
      this.index!.params!.filters!.maxDuration = null;
      this.index!.params!.filters!.departingDate = null;
      this.index!.params!.filters!.finishingDate = null;
      this.index!.params!.filters!.onSale = null;
    },
    clearFiltersAndSearch() {
      this.clearFilters();
      this.search(1);
    },
    sortUpdate(value: SortOption) {
      this.index!.updateIndexSort(value);
      this.search(1);
    },
    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
    },
    autocompleteSearch(autocomplete: {
      geoLocation: GeolocationCoordinates;
      keyword: string;
    }) {
      if (!this.index || !autocomplete) return;

      this.index.params.keyword = autocomplete.keyword ?? "";
      this.index.params.geoLocation!.latitude =
        autocomplete.geoLocation!.latitude ?? null;
      this.index.params.geoLocation!.longitude =
        autocomplete.geoLocation.longitude;

      this.search(1);
    },
    isKeywordSearchControlAvailable() {
      return typeof this.index?.params.keyword !== "undefined";
    },
    initialAutocompleteValue() {
      const urlParams = new URLSearchParams(window.location.search);

      if (
        !this.index ||
        (!this.index.params.keyword && urlParams.get("geoLocation"))
      ) {
        return "Near Me";
      }

      return this.index.params.keyword;
    },
    mapSearchPageToUtagSearch() {
      const searchPageData: SearchPageDataType = {
        productCodes: [],
        productNames: [],
        productStyles: [],
        productThemes: [],
        productPrices: [],
        productPositions: [],
        productBrand: [],
        productList: "Search Results",
      };

      this.trips.forEach((result: Trip, index: number) => {
        if (result.code) {
          searchPageData.productCodes.push(result.code);
        }

        if (result.name) {
          searchPageData.productNames.push(result.name);
        }

        if (result.styles) {
          searchPageData.productStyles.push(
            result.styles.map(({ name }) => name).join(",")
          );
        }

        if (result.themes) {
          searchPageData.productThemes.push(
            result.themes.map(({ title }) => title).join(",")
          );
        }

        if (this.currentPage && this.resultsPerPage) {
          searchPageData.productPositions.push(
            (this.currentPage - 1) * this.resultsPerPage + (index + 1)
          );
        }

        if (
          result.departure &&
          result.departure.lowestPrice &&
          result.departure.lowestPrice.discountedTotalPrice
        ) {
          searchPageData.productPrices.push(
            result.departure.lowestPrice.discountedTotalPrice
          );
        }
        if (
          this.index?._searchConfig?._env &&
          this.index?._searchConfig?._brand
        ) {
          searchPageData.productBrand.push(this.index?._searchConfig?._brand);
        }
      });
      this.addDataToUtag(searchPageData);
      this.fireUtagEvent(searchPageData);
    },
    addDataToUtag(searchPageData: SearchPageDataType) {
      // @ts-expect-error utag_data not in window object
      if (window?.utag_data) {
        // @ts-expect-error utag_data not in window object
        window.utag_data.impression_id = searchPageData.productCodes;
        // @ts-expect-error utag_data not in window object
        window.utag_data.impression_name = searchPageData.productNames;
        // @ts-expect-error utag_data not in window object
        window.utag_data.impression_list = searchPageData.productList;
        // @ts-expect-error utag_data not in window object
        window.utag_data.impression_brand = searchPageData.productBrand;
        // @ts-expect-error utag_data not in window object
        window.utag_data.impression_category = searchPageData.productThemes;
        // @ts-expect-error utag_data not in window object
        window.utag_data.impression_variant = searchPageData.productStyles;
        // @ts-expect-error utag_data not in window object
        window.utag_data.impression_position = searchPageData.productPositions;
        // @ts-expect-error utag_data not in window object
        window.utag_data.impression_price = searchPageData.productPrices;
      }
    },
    fireUtagEvent(searchPageData: SearchPageDataType) {
      const utagProductList = {
        event_type: "view_item_list",
        page_type: "search",
        virtual_page_name:
          this.$route.path && this.$route.query ? this.$route.fullPath : "",
        impression_id: searchPageData.productCodes,
        impression_name: searchPageData.productNames,
        impression_list: searchPageData.productList,
        impression_brand: searchPageData.productBrand,
        impression_category: searchPageData.productThemes,
        impression_variant: searchPageData.productStyles,
        impression_position: searchPageData.productPositions,
        impression_price: searchPageData.productPrices,
      };
      // @ts-ignore
      this.$utag.link(utagProductList);
    },
    setDrawerOffsetTop() {
      return (this.drawerOffsetTop = (
        document.querySelector(".header-bar") as HTMLElement
      )?.offsetHeight);
    },
  },
});
</script>
