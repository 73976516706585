<template>
  <div class="checkbox-filter" data-cy="checkbox-filter">
    <template v-for="(option, index) in computedOptions">
      <!-- @vue-ignore -->
      <Checkbox
        :id="componentUniqueId + '-' + index"
        :key="index"
        v-model="inputValue"
        :value="index"
        :name="name"
        class="u-margin-bottom--0"
      >
        {{ index }}
        <span v-if="option && option.count" class="checkbox-filter__count">{{
          option.count
        }}</span>
      </Checkbox>
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Checkbox from "atlas/src/components/Checkbox/Checkbox.vue";
import { CheckboxFilterProps } from "./Props";
import { componentUniqueIdMixin } from "~~/lib/mixins/componentUniqueId";

export default Vue.extend({
  name: "CheckboxFilter",
  components: {
    Checkbox,
  },
  mixins: [componentUniqueIdMixin],
  props: {
    name: {
      type: String as PropType<CheckboxFilterProps["name"]>,
      required: true,
    },
    value: {
      type: Array as PropType<CheckboxFilterProps["value"]>,
      required: true,
    },
    options: {
      type: Object as PropType<CheckboxFilterProps["options"]>,
      required: true,
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value: string[]) {
        this.$emit("input", value);
      },
    },
    computedOptions() {
      const options: Record<string, { count: number } | 0> = this.options;

      if (Object.keys(options).length === 0) {
        this.value.forEach((value) => {
          options[value] = 0;
        });
      }

      return options;
    },
  },
});
</script>

<style lang="scss">
@import "./checkbox-filter";
</style>
