<template>
  <div class="search-applied-filters" data-cy="search-applied-filters">
    <Chip
      v-for="filter in appliedFilters"
      :key="filter.name + filter.value"
      class="
        chip--outline
        u-margin-right--1
        u-margin-bottom--1
        u-text-transform--capitalize
      "
      :deletable="true"
      :delete-button-label="deleteButtonAriaLabel(filter)"
      @delete="removeFilter(filter)"
    >
      {{ filter.value }}
    </Chip>

    <SearchClearFiltersButton
      :filters="filters"
      @click="emitClear"
    ></SearchClearFiltersButton>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Chip from "atlas/src/components/Chip/Chip.vue";
import Filters from "search-client-js/src/Model/Filters";
import dayjs from "dayjs";
import SearchClearFiltersButton from "../SearchClearFiltersButton/SearchClearFiltersButton.vue";
import { SearchAppliedFiltersProps } from "./Props";

type AppliedFilter = {
  name: keyof Filters;
  value: string;
};

export default Vue.extend({
  name: "SearchAppliedFilters",
  components: {
    Chip,
    SearchClearFiltersButton,
  },
  props: {
    filters: {
      type: Object as PropType<SearchAppliedFiltersProps["filters"]>,
      required: true,
    },
  },
  computed: {
    appliedFilters(): AppliedFilter[] {
      const filters: AppliedFilter[] = [];

      this.filters.style.forEach((element) => {
        filters.push({
          name: "style",
          value: element,
        });
      });

      this.filters.theme.forEach((element) => {
        filters.push({
          name: "theme",
          value: element,
        });
      });

      if (this.filters.minDuration !== null) {
        filters.push({
          name: "minDuration",
          value: "minimum " + this.filters.minDuration + " days",
        });
      }

      if (this.filters.maxDuration !== null) {
        filters.push({
          name: "maxDuration",
          value: "maximum " + this.filters.maxDuration + " days",
        });
      }

      if (this.filters.departingDate !== null) {
        filters.push({
          name: "departingDate",
          value:
            "departing after " +
            dayjs(this.filters.departingDate).format("DD MMM YYYY"),
        });
      }

      if (this.filters.finishingDate !== null) {
        filters.push({
          name: "finishingDate",
          value:
            "finishing before " +
            dayjs(this.filters.finishingDate).format("DD MMM YYYY"),
        });
      }

      if (this.filters.onSale === true) {
        filters.push({
          name: "onSale",
          value: "Trips on sale",
        });
      }

      if (this.filters.lastMinuteDeals === true) {
        filters.push({
          name: "lastMinuteDeals",
          value: "Last minute deals (within 60 days)",
        });
      }

      return filters;
    },
  },
  methods: {
    removeFilter(filter: AppliedFilter) {
      if (filter.name !== "style" && filter.name !== "theme") {
        // eslint-disable-next-line vue/no-mutating-props
        (this.filters[filter.name] as null) = null;
        this.$emit("update", true);
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.filters[filter.name] = this.filters[filter.name].filter(
          (element) => element !== filter.value
        );
        this.$emit("update", true);
      }
    },
    emitClear() {
      this.$emit("clearfilters");
    },
    deleteButtonAriaLabel(filter: AppliedFilter) {
      return "Remove filter, button, Filter " + filter.value;
    },
  },
});
</script>
