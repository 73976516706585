<template>
  <div class="search-filters">
    <div class="search-filters__filter">
      <strong>Dates</strong>
      <DateRangeFilter
        :departing-value="departingDateModel"
        :finishing-value="finishingDateModel"
        :locale="$i18n.localeProperties.iso"
        @departing="departingDateModel = $event"
        @finishing="finishingDateModel = $event"
        @clear="clearDateRange()"
      ></DateRangeFilter>
    </div>

    <div class="search-filters__filter">
      <strong>Duration</strong>
      <MinMaxFilter
        :min-value="minDurationModel"
        :max-value="maxDurationModel"
        :min-facet="durationMinFacet"
        :max-facet="durationMaxFacet"
        :facets="durationFacets"
        data-cy="duration-filter"
        @min="minDurationModel = $event"
        @max="maxDurationModel = $event"
        @clear="clearDuration()"
      ></MinMaxFilter>
    </div>

    <div class="search-filters__filter">
      <strong>Travel Deals</strong>
      <CheckboxFilter
        v-model="travelDealsModel"
        :options="travelDealsFacets"
        name="travel-deals"
        data-cy="travel-deals-filter"
      ></CheckboxFilter>
    </div>

    <div v-if="displayStyleFilter" class="search-filters__filter">
      <strong>Styles</strong>
      <Popper
        :show-popper="showStylePopper"
        placement="top"
        @hide="showStylePopper = false"
      >
        <template slot="reference">
          <button class="search-filters__info" @click="showStylePopper = true">
            What are styles?
          </button>
        </template>
        <template slot="content">
          <p>
            <strong>Basix:</strong> Simple accommodation, local transport and
            lots of free time
          </p>

          <p>
            <strong>Original:</strong> Tourist-class hotels, some meals included
            and a great mix of included activities and free time
          </p>

          <p>
            <strong>Comfort:</strong> Comfortable accommodation, many included
            meals and activity, relaxed pace of travel
          </p>

          <p class="u-margin-bottom--0">
            <strong>Premium:</strong> 4+ star accommodation, exclusive premium
            inclusions, our most experienced leaders
          </p>
        </template>
      </Popper>
      <CheckboxFilter
        v-model="stylesModel"
        :options="styleFacets"
        name="styles"
        data-cy="style-filter"
      ></CheckboxFilter>
    </div>

    <div v-if="displayThemeFilter" class="search-filters__filter">
      <strong>Themes</strong>
      <Popper
        :show-popper="showThemePopper"
        placement="bottom"
        @hide="showThemePopper = false"
      >
        <template slot="reference">
          <button class="search-filters__info" @click="showThemePopper = true">
            What are themes?
          </button>
        </template>
        <template slot="content">
          Our themes categorise trips based on a common interest or activity,
          while showcasing the highlights of a destination.
        </template>
      </Popper>
      <CheckboxFilter
        v-model="themesModel"
        :options="themeFacets"
        name="themes"
        data-cy="theme-filter"
      ></CheckboxFilter>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable vue/no-mutating-props */
import Vue, { PropType } from "vue";
import dayjs from "dayjs";
import Popper from "atlas/src/components/Popper/Popper.vue";
import DateRangeFilter from "../DateRangeFilter/DateRangeFilter.vue";
import { SearchFiltersProps } from "./Props";
import MinMaxFilter from "~/components/MinMaxFilter/MinMaxFilter.vue";
import CheckboxFilter from "~/components/CheckboxFilter/CheckboxFilter.vue";

export default Vue.extend({
  name: "SearchFilters",
  components: {
    CheckboxFilter,
    DateRangeFilter,
    MinMaxFilter,
    Popper,
  },
  props: {
    filters: {
      type: Object as PropType<SearchFiltersProps["filters"]>,
      required: true,
    },
    facets: {
      type: Object as PropType<SearchFiltersProps["facets"]>,
      required: true,
    },
    facetsStats: {
      type: Object as PropType<SearchFiltersProps["facetsStats"]>,
      required: true,
    },
  },
  data() {
    return {
      showThemePopper: false,
      showStylePopper: false,
    };
  },
  computed: {
    travelDealsFacets() {
      return {
        "Trips on sale": null,
        "Last minute deals (within 60 days)": null,
      };
    },
    departingDateModel: {
      get(): Date | null {
        const departingDate = dayjs(this.filters.departingDate);

        if (departingDate.isValid()) {
          return departingDate.toDate();
        }

        return null;
      },
      set(value?: dayjs.ConfigType): void {
        const departingDate = dayjs(value);

        if (departingDate.isValid()) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.filters.departingDate = departingDate as any;
        } else {
          this.filters.departingDate = null;
        }

        this.emitUpdate();
      },
    },
    finishingDateModel: {
      get(): Date | null {
        const finishingDate = dayjs(this.filters.finishingDate);

        if (finishingDate.isValid()) {
          return finishingDate.toDate();
        }

        return null;
      },
      set(value?: dayjs.ConfigType): void {
        const finishingDate = dayjs(value);

        if (finishingDate.isValid()) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.filters.finishingDate = finishingDate as any;
        } else {
          this.filters.finishingDate = null;
        }

        this.emitUpdate();
      },
    },
    minDurationModel: {
      get(): number | null {
        return this.filters.minDuration;
      },
      set(value?: number): void {
        this.filters.minDuration = value ?? null;
        this.emitUpdate();
      },
    },
    maxDurationModel: {
      get(): number | null {
        return this.filters.maxDuration;
      },
      set(value?: number): void {
        this.filters.maxDuration = value ?? null;
        this.emitUpdate();
      },
    },
    durationFacets() {
      return this.facets?.duration ?? {};
    },
    durationMinFacet() {
      return this.facetsStats?.duration?.min ?? null;
    },
    durationMaxFacet() {
      return this.facetsStats?.duration?.max ?? null;
    },
    themeFacets() {
      if (this.facets?.themes) {
        const themes: Record<string, { count: number }> = {};
        for (const [key, value] of Object.entries(this.facets.themes)) {
          themes[key] = {
            count: value as number,
          };
        }
        return themes;
      }

      return {};
    },
    styleFacets() {
      if (this.facets?.styles) {
        const sortedStyles: Record<string, { count: number }> = {};

        this.sortedStylesList().map((styleName) => {
          return (sortedStyles[styleName] = {
            count: this.facets.styles[styleName],
          });
        });

        return sortedStyles;
      }

      return {};
    },
    displayThemeFilter() {
      if (
        Object.keys(this.themeFacets).length === 0 &&
        this.filters.theme.length === 0
      ) {
        return false;
      }

      return true;
    },
    displayStyleFilter() {
      if (
        Object.keys(this.styleFacets).length === 0 &&
        this.filters.style.length === 0
      ) {
        return false;
      }

      return true;
    },
    stylesModel: {
      get() {
        return this.filters.style;
      },
      set(value: string[]) {
        this.filters.style = value;
        this.emitUpdate();
      },
    },
    travelDealsModel: {
      get(): Array<string> {
        const values = [];
        if (this.filters.onSale) {
          values.push("Trips on sale");
        }
        if (this.filters.lastMinuteDeals) {
          values.push("Last minute deals (within 60 days)");
        }
        return values;
      },
      set(value: Array<string>) {
        this.filters.onSale = null;
        this.filters.lastMinuteDeals = null;
        if (value.includes("Trips on sale")) {
          this.filters.onSale = true;
        }
        if (value.includes("Last minute deals (within 60 days)")) {
          this.filters.lastMinuteDeals = true;
        }
        this.emitUpdate();
      },
    },
    themesModel: {
      get() {
        return this.filters.theme;
      },
      set(value: string[] | null) {
        this.filters.theme = value;
        this.emitUpdate();
      },
    },
  },
  methods: {
    clearDateRange() {
      this.filters.departingDate = null;
      this.filters.finishingDate = null;
      this.emitUpdate();
    },
    emitUpdate() {
      this.$emit("update", true);
    },
    clearDuration() {
      this.filters.minDuration = null;
      this.filters.maxDuration = null;
      this.emitUpdate();
    },
    sortedStylesList() {
      if (!this.facets.styles) return [];
      if (Object.keys(this.facets.styles).length === 0) return [];

      const mainStyles = ["Basix", "Original", "Comfort", "Premium"];
      return Object.keys(this.facets.styles).sort((a, b) => {
        const aIndex = mainStyles.indexOf(a);
        const bIndex = mainStyles.indexOf(b);

        if (aIndex === -1 && bIndex === -1) return 0;
        if (aIndex === -1) return 1;
        if (bIndex === -1) return -1;
        if (aIndex !== -1 && bIndex !== -1) {
          return aIndex < bIndex ? -1 : 1;
        }

        return 0;
      });
    },
  },
});
</script>

<style lang="scss">
@import "./search-filters";
</style>
