<template>
  <div class="search-autocomplete">
    <Autocomplete
      :value="value"
      :items="autocompleteResults"
      :placeholder="autocompletePlaceholder"
      @input="updateAutocomplete"
      @clear="clearInput"
      @itemselect="search"
      @select="search"
    ></Autocomplete>

    <Button
      ref="autocompleteButton"
      class="button--primary"
      :on-click="searchButtonClicked"
      data-cy="autocomplete-button"
      aria-label="search"
    >
      <span class="search-autocomplete__button-text">Search</span>
      <Icon name="arrow-right" class="search-autocomplete__icon"></Icon>
    </Button>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import ProductAutocomplete from "search-client-js/src/Service/ProductAutocomplete";
import AutocompleteModel from "search-client-js/src/Model/Autocomplete";
import SearchModel from "search-client-js/src/Model/Search";
import Button from "atlas/src/components/Button/Button.vue";
import Icon from "atlas/src/components/Icon/Icon.vue";
import Autocomplete from "~/components/Autocomplete/Autocomplete.vue";
import "atlas/src/components/Icon/icons/arrow-right.js";
import "atlas/src/components/Icon/icons/near-me.js";

type Value = {
  id: string;
  value: string;
  icon?: string;
  clearAutocomplete?: boolean;
};

type Data = {
  value: Value;
  autocompleteResults: Value[];
};

type SearchValue = {
  keyword: string;
  geoLocation: Record<string, number | null>;
};

const emptyValue: Value = {
  id: "",
  value: "",
};

const nearMeValue: Value = {
  id: "Near Me",
  value: "Near Me",
  icon: "near-me",
  clearAutocomplete: true,
};

export default Vue.extend({
  name: "SearchAutocomplete",
  components: {
    Autocomplete,
    Button,
    Icon,
  },
  props: {
    autocomplete: {
      type: Object as PropType<ProductAutocomplete>,
      required: true,
    },
    initialSearch: {
      type: String,
      required: true,
    },
    autocompletePlaceholder: {
      type: String,
      required: false,
      default: "Where do you want to go?",
    },
  },
  data(): Data {
    return {
      value: emptyValue,
      autocompleteResults: [nearMeValue],
    };
  },
  created() {
    if (this.initialSearch === nearMeValue.id) {
      this.value = nearMeValue;
    } else {
      this.value = {
        id: this.initialSearch,
        value: this.initialSearch,
      };
    }
  },
  methods: {
    searchButtonClicked() {
      this.search(this.value);
    },
    updateAutocomplete(value: Value) {
      this.value = value;

      if (value.id.length < 3) {
        this.autocompleteResults = [nearMeValue];
      } else {
        this.autocomplete
          .search(value.id)
          .then((search: SearchModel) => {
            // @ts-ignore Fix type in search-client-js
            const searchResults: AutocompleteModel[] = search.results;

            const results = searchResults.map((result) => ({
              id: result.objectID,
              value: result.highlightedValue,
            }));

            this.autocompleteResults = results;
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },
    search(value: Value) {
      const searchValue = {
        keyword: "",
        geoLocation: {
          latitude: null,
          longitude: null,
        },
      };

      this.$emit("updateLoadingState", true);

      if (value.id === nearMeValue.id) {
        this.emitGeoLocation(searchValue, value);
      } else {
        searchValue.keyword = value.id;
        this.value = value;
        this.$emit("search", searchValue);
      }
    },
    clearInput() {
      this.updateAutocomplete(emptyValue);
      this.search(emptyValue);
    },
    emitGeoLocation(searchValue: SearchValue, value: Value) {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            searchValue.geoLocation = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };

            this.value = value;
            this.$emit("search", searchValue);
          },
          () => {
            this.$emit("updateLoadingState", false);
            this.value = emptyValue;
            window.alert(
              "Intrepid Travel was denied permission to access your location. Give your browser and Intrepid Travel permission to use your current location and try again."
            );
          },
          {
            maximumAge: 3600000,
          }
        );
      } else {
        this.value = emptyValue;
      }
    },
  },
});
</script>

<style lang="scss">
@import "./search-autocomplete";
</style>
