<template>
  <Button
    class="button--secondary button--block button--large"
    :on-click="emitClick"
    data-cy="search-filter-button"
  >
    {{ buttonText }}
  </Button>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Button from "atlas/src/components/Button/Button.vue";
import { SearchFilterButtonProps } from "./Props";

export default Vue.extend({
  name: "SearchFilterButton",
  components: {
    Button,
  },
  props: {
    filters: {
      type: Object as PropType<SearchFilterButtonProps["filters"]>,
      required: true,
    },
  },
  computed: {
    activeFilterCount(): number {
      let filterCount = 0;

      filterCount += this.filters.style.length;
      filterCount += this.filters.theme.length;

      if (this.filters.minDuration != null) {
        filterCount++;
      }

      if (this.filters.maxDuration != null) {
        filterCount++;
      }

      if (this.filters.departingDate != null) {
        filterCount++;
      }

      if (this.filters.finishingDate != null) {
        filterCount++;
      }

      if (this.filters.onSale === true) {
        filterCount++;
      }

      return filterCount;
    },
    buttonText(): string {
      if (this.activeFilterCount === 0) {
        return "Filters";
      }

      return "Filters (" + this.activeFilterCount + ")";
    },
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
});
</script>
