import { Trip } from "~/lib/types/Mocks/Trip";
import { Style } from "~/lib/types/Mocks/Style";
import { Destination } from "~/lib/types/Mocks/Destination";
import { Departure } from "~/lib/types/Mocks/Departure";
import { TripCardProps } from "~/components/TripCard/Props";

const convertThemes = (themes: Trip["themes"]): TripCardProps["themes"] => {
  return Array.isArray(themes)
    ? themes?.map(({ title }) => ({ value: title }))
    : [];
};

const convertStyles = (styles: Style[] | undefined): string[] | undefined => {
  return styles?.map(({ name }) => name);
};

const convertDestinations = (
  destinations: Destination[] | undefined
): string[] | undefined => {
  return destinations?.map(({ name }) => name);
};

const convertPrice = (
  departure: Departure | undefined
): TripCardProps["price"] | undefined => {
  if (departure?.lowestPrice?.totalPrice) {
    return {
      value: departure.lowestPrice.totalPrice,
      currencyCode: departure.lowestPrice.currencyCode,
    };
  }
};

const convertDiscountPrice = (
  departure: Departure | undefined
): TripCardProps["discountPrice"] | undefined => {
  if (
    departure?.lowestPrice?.onSale &&
    departure?.lowestPrice?.discountedTotalPrice
  ) {
    return {
      value: departure.lowestPrice.discountedTotalPrice,
      currencyCode: departure.lowestPrice.currencyCode,
    };
  }
};

const showPrice = (departure: Departure): boolean => {
  if (!departure || !departure.placesLeft) {
    return false;
  }

  return true;
};

const convertStartDate = (departure: Departure): Date | undefined => {
  if (!departure || !departure.startDate) return;

  return new Date(departure.startDate * 1000);
};

export const AlgoliaTripCardDecorator =
  () =>
  (trip: Trip, type: TripCardProps["type"] = "default"): TripCardProps => {
    const tripCard: TripCardProps = {
      type,
      name: trip.name,
      code: trip.code,
      duration: trip.duration,
      styles: convertStyles(trip?.styles),
      themes: convertThemes(trip?.themes),
      destinations: convertDestinations(trip?.destinations),
      url: trip.url,
      showPrice: false,
      map: trip?.map,
    };

    if (trip.departure) {
      tripCard.price = convertPrice(trip?.departure);
      tripCard.discountPrice = convertDiscountPrice(trip?.departure);
      tripCard.showPrice = showPrice(trip?.departure);
      tripCard.startDate = convertStartDate(trip?.departure);
    }

    return tripCard;
  };
