<template>
  <div class="l-grid">
    <ErrorBoundary
      v-for="(trip, i) in trips"
      :key="i + '_' + trip.code"
      class="
        l-grid__cell
        l-grid__cell--flex
        l-grid__cell--12-col
        l-grid__cell--6-col-desktop
        l-grid__cell--4-col-tablet
      "
    >
      <TripCard v-bind="trip" />
    </ErrorBoundary>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import TripCard from "~/components/TripCard/TripCard.vue";
import { TripCardProps } from "~/components/TripCard/Props";
import ErrorBoundary from "~~/components/ErrorBoundary/ErrorBoundary.vue";

export default Vue.extend({
  name: "SearchResults",
  components: {
    TripCard,
    ErrorBoundary,
  },
  props: {
    trips: {
      type: Array as PropType<TripCardProps[]>,
      default: [] as TripCardProps[],
    },
  },
});
</script>
