<template>
  <ErrorBoundary>
    <SearchPage
      :page="page"
      :pageHeadline="pageHeadline"
      :tailorMadeCard="tailorMadeCard"
    />
  </ErrorBoundary>
</template>

<script lang="ts">
import Vue from "vue";
import { InitPageParams } from "lib/utils/initPage";
import { initPage } from "~~/lib/utils/initPage";
import SearchPage from "~/components/SearchPage/SearchPage.vue";
import { SearchPageProps } from "~/components/SearchPage/Props";
import ErrorBoundary from "~~/components/ErrorBoundary/ErrorBoundary.vue";
import { getBaseLink } from "~~/lib/utils/link/getBaseLink";

export default Vue.extend({
  name: "Search",
  components: {
    SearchPage,
    ErrorBoundary,
  },
  async asyncData({
    store,
    i18n,
    route,
    params,
    $config,
  }): Promise<SearchPageProps> {
    const requestParams: InitPageParams = {
      locale_iso: i18n.localeProperties.iso,
      locale_code: i18n.locale,
      locale_domain: $config.public.baseUrl,
      path: route.path,
      slug: getBaseLink(route.path),
      current_path: getBaseLink(route.path),
      currency_code: params.currencyCode || i18n.localeProperties.currencyCode,
    };

    await Promise.all(initPage(store, requestParams));

    const searchPage = (await $fetch("/api/nuxt/search/page", {
      params: requestParams,
    })) as SearchPageProps;

    return searchPage;
  },
  head() {
    return {
      meta: [
        {
          name: "robots",
          content: "noindex, nofollow",
        },
      ],
    };
  },
});
</script>
