<template>
  <div class="date-range-filter" data-cy="date-range-filter">
    <DatePicker
      :id="componentUniqueId + '_' + 'departing-date'"
      v-model="departingDateModel"
      name="departing-date"
      label="Departing"
      :locale="locale"
      :disabled-dates="disabledDates"
      :highlighted="highlightedDates"
      type="full-width"
      data-cy="date-range-filter__departing"
      @closed="departingDateModelClosed"
    ></DatePicker>

    <DatePicker
      :id="componentUniqueId + '_' + 'finishing-date'"
      v-model="finishingDateModel"
      class="u-margin-top--1"
      name="finishing-date"
      :open-date="finishingDateOnOpenStartOn"
      label="Finishing"
      :locale="locale"
      :disabled-dates="disabledDates"
      :highlighted="highlightedDates"
      type="full-width"
      data-cy="date-range-filter__finishing"
      @closed="finishingDateModelClosed"
    ></DatePicker>

    <div class="u-text-align--right">
      <Button
        class="button--small button--secondary u-margin-top--1"
        :on-click="clearDateFilter"
        data-cy="date-range-filter__clear"
      >
        Clear dates
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Button from "atlas/src/components/Button/Button.vue";
import DatePicker from "atlas/src/components/DatePicker/components/Datepicker.vue";
import dayjs, { extend } from "dayjs";
import utc from "dayjs/plugin/utc.js";
import { DateRangeFilterProps } from "./Props";
import { componentUniqueIdMixin } from "~~/lib/mixins/componentUniqueId";

extend(utc);

export default Vue.extend({
  name: "DateRangeFilter",
  components: {
    Button,
    DatePicker,
  },
  mixins: [componentUniqueIdMixin],
  props: {
    locale: {
      type: String as PropType<DateRangeFilterProps["locale"]>,
      required: false,
      default: "en",
    },
    departingValue: {
      type: Date as PropType<DateRangeFilterProps["departingValue"]>,
      required: false,
      default: null,
    },
    finishingValue: {
      type: Date as PropType<DateRangeFilterProps["finishingValue"]>,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      tempDepartingDateModel: null as dayjs.ConfigType | null,
      tempFinishingDateModel: null as dayjs.ConfigType | null,
      disabledDates: {
        to: dayjs
          .utc(new Date(new Date().setHours(23, 59, 59)))
          .subtract(1, "days")
          .toDate(),
        from: null,
      },
    };
  },
  computed: {
    departingDateModel: {
      get(): Date | null {
        return this.departingValue;
      },
      set(value?: dayjs.ConfigType) {
        this.tempDepartingDateModel = value;
      },
    },
    finishingDateModel: {
      get(): Date | null {
        return this.finishingValue;
      },
      set(value?: dayjs.ConfigType) {
        this.tempFinishingDateModel = value;
      },
    },
    finishingDateOnOpenStartOn() {
      if (!this.departingValue) {
        return null;
      }
      if (this.finishingValue) {
        return this.finishingValue;
      }
      return this.departingValue;
    },
    highlightedDates() {
      const highlighted = [];
      if (this.departingValue) {
        highlighted.push(dayjs.utc(this.departingValue).toDate());
      }
      if (this.finishingValue) {
        highlighted.push(dayjs.utc(this.finishingValue).toDate());
      }
      return {
        dates: highlighted,
      };
    },
  },
  methods: {
    clearDateFilter() {
      this.$emit("clear", true);
    },
    setDisabledDates() {
      this.disabledDates = {
        to: dayjs
          .utc(new Date(new Date().setHours(23, 59, 59)))
          .subtract(1, "days")
          .toDate(),
        from: null,
      };
    },
    departingDateModelClosed() {
      if (this.tempDepartingDateModel) {
        if (
          dayjs.utc(this.tempDepartingDateModel).diff(this.disabledDates.to) <
            0 ||
          dayjs.utc(this.tempDepartingDateModel).diff(this.disabledDates.from) >
            0
        ) {
          this.$emit("departing", null);
        } else if (
          this.finishingDateModel === null ||
          dayjs
            .utc(this.tempDepartingDateModel)
            .diff(dayjs.utc(this.finishingDateModel)) < 0
        ) {
          this.$emit("departing", this.tempDepartingDateModel);
        } else {
          this.$emit("finishing", null);
          this.$emit("departing", this.tempDepartingDateModel);
        }

        this.setDisabledDates();
      }
    },
    finishingDateModelClosed() {
      if (this.tempFinishingDateModel) {
        if (
          dayjs.utc(this.tempFinishingDateModel).diff(this.disabledDates.to) <
            0 ||
          dayjs.utc(this.tempFinishingDateModel).diff(this.disabledDates.from) >
            0
        ) {
          this.$emit("finishing", null);
        } else if (
          this.departingDateModel === null ||
          dayjs
            .utc(this.tempFinishingDateModel)
            .diff(dayjs.utc(this.departingDateModel)) > 0
        ) {
          this.$emit("finishing", this.tempFinishingDateModel);
        } else {
          this.$emit("departing", null);
          this.$emit("finishing", this.tempFinishingDateModel);
        }

        this.setDisabledDates();
      }
    },
  },
});
</script>
